<template>
  <v-card class="mb-5">
    <v-card-text class="pt-1">
      <custom-snackbar
        ref="snackbar"
      />

      <v-toolbar flat class="d-flex flex-row-reverse">
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" class="mr-3" v-on="on">
                  <v-icon dark>mdi-printer</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="exportFile('PDF')">
                  <v-list-item-title>Exportar PDF</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportFile('Excel')">
                  <v-list-item-title>Exportar Excel</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn color="info" dark outlined v-bind="attrs" v-on="on">
              Adicionar Qualidade do Tapete
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-toolbar color="info" dark>
              <span class="headline">{{ formTitle }}</span>
            </v-toolbar>
            <v-form ref="form" v-model="valid" lazy-validation v-if="showForm">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <EmpresasSelect v-model="editedItem.empresa_id" obrigatorio/>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ProducoesSelect
                        v-model="editedItem.producao_id"
                        :empresa="editedItem.empresa_id"
                        @input="buscarTalhoes"
                        obrigatorio
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-select
                        v-model.number="editedItem.talhao_id"
                        :items="talhoes"
                        label="Talhão"
                        :rules="talhaoRule"
                        item-text="talhao"
                        item-value="talhao_id"
                        dense
                        outlined
                        @change="setVariedadeCultura"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-select
                          v-model.number="editedItem.variedade_cultura_id"
                          :items="variedade_cultura"
                          label="Variedade de Cultura"
                          :rules="culturaRule"
                          item-text="nome"
                          item-value="id"
                          dense
                          outlined
                          disabled
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.data"
                        :value="editedItem.data"
                        label="Data"
                        dense
                        outlined
                        :rules="dataRule"
                        type="date"
                      />
                    </v-col>

                    <v-col>
                      <v-autocomplete
                        v-model.number="editedItem.resistencia"
                        :items="resistencia"
                        label="Resistência"
                        :rules="resistenciaRule"
                        item-text="nome"
                        item-value="id"
                        dense
                        outlined
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.peso_aproximado"
                        label="Peso Aproximado (kg)"
                        dense
                        outlined
                        :rules="[pesoAproximadoRule]"
                        reverse
                        @keyup="onPesoAproximado($event)"
                      />
                    </v-col>

                    <v-col>
                      <v-autocomplete
                        v-model.number="editedItem.peso_status"
                        :items="peso_status"
                        label="Peso"
                        item-text="nome"
                        item-value="id"
                        dense
                        outlined
                        disabled
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="editedItem.motivo"
                        outlined
                        name="input-7-4"
                        label="Motivo/Observação"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-switch
                        v-model.number="editedItem.status"
                        inset
                        color="success"
                      >
                        <template v-slot:label>
                          Status:
                          <custom-label
                            class="ml-2"
                            :color="$label.getColor(editedItem.status)"
                            :text="$label.getStatusMessage(editedItem.status)"
                          />
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="validate"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-form>
            <v-card-text v-else>
              <div class="text-center p-5">
                <h4 class="mb-4">
                  Aguarde...
                </h4>
                <v-progress-circular
                  :size="100"
                  :width="2"
                  color="primary"
                  indeterminate
                />
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="500px"
        >
          <v-card class="bg-danger text-white">
            <v-card-title class="headline word-breaker">
              <p>
                Tem certeza que deseja excluir a qualidade do <br>
                tapete {{ editedItem.id }}?
              </p>
            </v-card-title>

            <v-card-text class="d-flex justify-center">
              <v-progress-circular
                v-show="loadingDelete"
                :size="50"
                :width="6"
                color="white"
                indeterminate
              />
            </v-card-text>

            <v-card-actions v-show="!loadingDelete">
              <v-spacer />
              <v-btn
                color="white"
                text
                outlined
                large
                @click="closeDelete"
              >
                Não
              </v-btn>
              <v-btn
                color="white"
                text
                outlined
                large
                @click="deleteItemConfirm"
              >
                Sim
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

      <!-- Filtros -->
      <v-row class="mt-3 text-sm-body-1">
        <v-col class="py-0 col-xs-12 col-sm-6 col-md-2">
          <DatePicker label="Data Início" v-model="dataInicioFilter" @input="fieldsSelected()"/>
        </v-col>

        <v-col class="py-0 col-xs-12 col-sm-6 col-md-2">
          <DatePicker label="Data Fim" v-model="dataFimFilter" @input="fieldsSelected()"/>
        </v-col>

        <v-col class="py-0 col-12 col-md-6 col-lg-4">
          <EmpresasSelect v-model="empresaFilterValue" @input="fieldsSelected()" mostra-todos-label></EmpresasSelect>
        </v-col>

        <v-col class="py-0 col-12 col-md-6 col-lg-4">
          <ProducoesSelect v-model="producaoFilterValue" @input="fieldsSelected()" :empresa="empresaFilterValue" multiple mostra-todos-label></ProducoesSelect>
        </v-col>

        <v-col class="py-0 col-12 col-md-6 col-lg-3">
          <v-select
            dense
            outlined
            label="Variedade de Cultura"
            item-text="nome"
            item-value="id"
            v-model="culturaFilterValue"
            :items="culturaOptions"
            @input="fieldsSelected()"
          />
        </v-col>

        <v-col class="py-0 col-12 col-md-6 col-lg-2">
          <v-select
            dense
            outlined
            label="Resistência"
            item-text="text"
            item-value="value"
            v-model="resistenciaFilterValue"
            :items="resistenciaOptions"
            @input="fieldsSelected()"
          />
        </v-col>

        <v-col class="py-0 col-12 col-md-6 col-lg-2">
          <v-select
            dense
            outlined
            label="Peso"
            item-text="text"
            item-value="value"
            v-model="pesoStatusFilterValue"
            :items="pesoStatusOptions"
            @input="fieldsSelected()"
          />
        </v-col>

        <v-col class="py-0 col-12 col-md-6 col-lg-2">
          <v-select
            dense
            outlined
            label="Status"
            v-model="statusFilterValue"
            :items="statusOptions"
            @input="fieldsSelected()"
          />
        </v-col>

        <v-col class="py-0 col-xs-12 col-sm-12 col-md-3 col-lg-2">
          <v-text-field
            dense
            outlined
            label="Pesquisar"
            append-icon="mdi-magnify"
            v-model="search"
            @input="fieldsSelected()"
          />
        </v-col>
         <v-col class="py-0 col-12 col-xs-12 col-sm-12 col-lg-1">
          <v-tooltip
            top
            v-model="pendingSearch"
            color="warning"
            max-width="139px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              max-width="20px"
              outlined
              block
              v-bind="attrs"
              v-on="on"
              color="info"
              class="v-btn--height-fix"
              @click="getData"
            >
              <v-icon dark>
                mdi-cached
               </v-icon>
              </v-btn>
              </template>
              <span>Busca pendente</span>
            </v-tooltip>
          </v-col>
      </v-row>

      <!-- Dados -->
      <v-row>
        <v-col>
          <v-data-table
            dense
            sort-by="data"
            sort-desc="true"
            :headers="headers"
            :items="data"
            :search="search"
            :items-per-page="$dataTableConfig.getItemsPerPage()"
            :footer-props="$dataTableConfig.getFooterProps()"
            :loading-text="$dataTableConfig.getLoadText()"
            :page="page"
            :page-count="pageCount"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
          >
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              ></v-progress-linear>
            </template>

            <template v-slot:[`item.peso_aproximado`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.peso_aproximado) }}</span>
            </template>

            <template v-slot:[`item.data`]="{ item }">
              <span>{{ format_date(item.data) }}</span>
            </template>

            <template v-slot:[`item.resistencia`]="{ item }">
              <span>{{ getResistencia(item.resistencia) }}</span>
            </template>

            <template v-slot:[`item.peso_status`]="{ item }">
              <span class="num_margin">{{ getPesoStatus(item.peso_status) }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <custom-label
                :color="$label.getColor(item.status)"
                :text="$label.getStatusMessage(item.status)"
              />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-spacer />
              <div>
                <v-row class="justify-center mt-1">
                  <v-col class="col-lg-6">
                    <custom-alert
                     text="Não foi possível obter essas informações"
                    />
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    // General
    search: '',
    dialog: false,
    dialogDelete: false,
    loading: true,
    loadingDelete: false,
    showForm: true,
    status: false,
    valid: true,
    pendingSearch: false,

    // Formatting
    mask: {
      decimal: ',',
      thousands: '',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: true,
    },

    // Validation Rules
    empresaRule: [(v) => !!v || 'Necessário informar a empresa da Qualidade do Tapete'],
    producaoRule: [(v) => !!v || 'Necessário informar a produção da Qualidade do Tapete'],
    talhaoRule: [(v) => !!v || 'Necessário informar o talhão da Qualidade do Tapete'],
    culturaRule: [(v) => !!v || 'Necessário informar a variedade de cultura da Qualidade do Tapete'],
    dataRule: [(v) => !!v || 'Necessário informar a data da Qualidade do Tapete'],
    resistenciaRule: [(v) => !!v || 'Necessário informar a resistência da Qualidade do Tapete'],
    pesoStatusRule: [(v) => !!v || 'Necessário informar o nome da Qualidade do Tapete'],
    motivoRule: [(v) => !!v || 'Necessário informar o nome da Qualidade do Tapete'],

    // Filters
    dataInicioFilter: '',
    dataFimFilter: '',
    statusFilterValue: 1,
    empresaFilterValue: '',
    producaoFilterValue: '',
    culturaFilterValue: '',
    resistenciaFilterValue: '',
    pesoStatusFilterValue: '',
    empresasOptions: [],
    empresa: [],
    producao: [],
    variedade_cultura: [],
    talhoes: [],
    resistenciaOptions: [
      { value: '', text: 'Todos' },
      { value: 1, text: 'Boa' },
      { value: 2, text: 'Regular' },
      { value: 3, text: 'Fraca' },
    ],
    pesoStatusOptions: [
      { value: '', text: 'Todos' },
      { value: 1, text: 'Leve' },
      { value: 2, text: 'Normal' },
      { value: 3, text: 'Pesado' },
    ],
    statusOptions: [
      { value: '', text: 'Todos' },
      { value: 1, text: 'Ativo' },
      { value: 0, text: 'Inativo' },
    ],
    empresasOptionsFilter: [
      { id: '', nome: 'Todas' },
    ],
    producaoOptionsFilter: [
      { id: '', sigla: 'Todas' },
    ],
    culturaOptions: [
      { id: '', nome: 'Todos' },
    ],
    resistencia: [
      { id: 1, nome: 'Boa' },
      { id: 2, nome: 'Regular' },
      { id: 3, nome: 'Fraca' },
    ],
    peso_status: [
      { id: 1, nome: 'Leve' },
      { id: 2, nome: 'Normal' },
      { id: 3, nome: 'Pesado' },
    ],

    // Pagination
    page: 1,
    pageCount: 0,
    options: {},
    totalItems: 0,

    // Form Data
    data: [],
    editedIndex: -1,
    editedItem: {
      nome: '',
      talhao_id: 0,
      cultura_id: 0,
      status: 1,
      nome_cientifico: 'teste',
      descricao: 'teste',
      codigo_agp: 1,
      flg_arp: 1,
      data: '',
      peso_aproximado: '',
      peso_status: 0,
    },
    defaultItem: {
      nome: '',
      talhao_id: 0,
      cultura_id: 0,
      status: 1,
      nome_cientifico: 'teste',
      descricao: 'teste',
      codigo_agp: 1,
      flg_arp: 1,
      data: '',
      peso_aproximado: '',
      peso_status: 0,
    },

  }),
  computed: {
    pesoAproximadoRule () {
      let valid = true
      let msg = ''
      let valor = this.editedItem.peso_aproximado

      if (valor === '') {
        valid = false
        msg = 'Necessário informar o peso aproximado da Qualidade do Tapete'
      }

      if (valor <= 0) {
        valor = parseFloat(valor.replace(',', '.'))
        valid = false
        msg = 'Peso aproximado da Qualidade do Tapete deve ser maior que zero'
      }

      return () => valid || `${msg}`
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    formTitle () {
      return this.editedIndex === -1
        ? 'Adicionar Qualidade do Tapete'
        : 'Editar Qualidade do Tapete'
    },
    formatNumber (number) {
      return parseFloat(number).toFixed(4).toString().replace('.', ',')
    },
    headers () {
      return [
        { text: '#', align: 'start', value: 'id', class: 'custom-table--header' },
        { text: 'Empresa', value: 'empresas.nome', class: 'custom-table--header' },
        { text: 'Produção', value: 'producoes.sigla', class: 'custom-table--header' },
        { text: 'Talhão', value: 'talhao_nome', class: 'custom-table--header' },
        { text: 'Variedade de Cultura', value: 'variedades_culturas.nome', class: 'custom-table--header' },
        { text: 'Data', value: 'data', class: 'custom-table--header' },
        { text: 'Resistência', value: 'resistencia', class: 'custom-table--header' },
        { text: 'Peso', value: 'peso_status', class: 'custom-table--header' },
        { text: 'Peso (kg)', value: 'peso_aproximado', align: 'right', class: 'custom-table--header' },
        { text: 'Status', value: 'status', class: 'custom-table--header' },
        { text: 'Ações', value: 'actions', sortable: false, class: 'custom-table--header' },
      ]
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    },
    options: {
      handler () {
        this.getData()
      },
    },
  },
  created () {},
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Qualidade dos Tapetes' },
    ])

    this.initialize()
    this.getEmpresasOptions()
    this.getVariedadeCultura()
  },
  methods: {
    initialize () {
      this.loading = false
      this.dataInicioFilter = this.$stringFormat.dateToISO(this.$date.getDateNow())
      this.dataFimFilter = this.$stringFormat.dateToISO(this.$date.getDateNow())
      this.defaultItem.data = this.$stringFormat.dateToISO(this.$date.getDateNow())
      this.editedItem.data = this.$stringFormat.dateToISO(this.$date.getDateNow())
    },
    fieldsSelected () {
      this.pendingSearch = true
    },
    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    getData () {
      this.loading = true
      this.pendingSearch = false
      this.data = []
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const dataAtual = !this.dataInicioFilter && !this.dataFimFilter ? 1 : 0
      const params = {
        page,
        qtde: itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0],
        data_atual: dataAtual,
        empresa_id: this.empresaFilterValue,
        producao_id: this.producaoFilterValue,
        variedade_cultura_id: this.culturaFilterValue,
        resistencia: this.resistenciaFilterValue,
        peso: this.pesoStatusFilterValue,
        status: this.statusFilterValue.toString(),
        data_inicio: this.dataInicioFilter,
        data_fim: this.dataFimFilter,
        search: this.search,
      }

      ApiService.get('/qualidade-tapete', '?' + this.$stringFormat.queryParamsRecursive(params)).then((res) => {
        const dados = res.data

        if (dataAtual && dados.data.length > 0) {
          this.dataInicioFilter = dados.data[0].data
          this.dataFimFilter = dados.data[0].data
        }

        this.loading = false
        this.totalItems = dados.total
        this.pageCount = dados.pageCount
        this.data = dados.data
      }).catch((error) => {
        this.loading = false
        console.error('Erro: ', error)
      })
    },

    getVariedadeCultura () {
      ApiService.get('/variedade-cultura').then((res) => {
        this.variedade_cultura = res.data.data

        for (const c of this.variedade_cultura) {
          this.culturaOptions.push(c)
        }
      })
    },
    getEmpresasOptions () {
      this.empresasOptions = this.$empresas.list(true)
      this.empresasOptionsFilter = this.$empresas.list(true)
    },

    setProducoesOptionsFIlter (idEmpresa) {
      this.producaoOptionsFilter = []
      this.pendingSearch = true

      if (idEmpresa !== 'Todas') {
        for (const empresa of this.empresasOptionsFilter) {
          if (idEmpresa === empresa.nome) {
            idEmpresa = empresa.id
          }
        }

        ApiService.get('/producao').then((res) => {
          const producoesFiltro = []
          producoesFiltro.push({ sigla: 'Todas', id: '' })

          for (const producao of res.data.data) {
            if (producao.empresa_id === idEmpresa) {
              producoesFiltro.push({
                id: producao.id,
                sigla: producao.sigla,
              })
            }
          }

          this.producaoOptionsFilter = producoesFiltro
          this.producaoFilterValue = ''
        })
      } else {
        const producoesFiltro = []

        producoesFiltro.push({
          sigla: 'Todas',
          id: '',
        })

        this.producaoOptionsFilter = producoesFiltro
        this.producaoFilterValue = ''
      }
    },

    setProducoesOptions (idEmpresa) {
      this.producoesOptions = []

      ApiService.get('/producao').then((res) => {
        const producoes = []

        for (const producao of res.data.data) {
          if (producao.empresa_id === idEmpresa) {
            producoes.push({
              id: producao.id,
              sigla: producao.sigla,
            })
          }
        }

        this.producao = producoes
      })
    },

    onPesoAproximado (event) {
      const valor = parseFloat(event.target.value.replace(',', '.'))

      if (valor < 4.6) {
        this.editedItem.peso_status = 1
      } else if (valor >= 4.6 && valor < 5.6) {
        this.editedItem.peso_status = 2
      } else {
        this.editedItem.peso_status = 3
      }
    },

    format_date (value) {
      return value.split('-').reverse().join('/')
    },

    getResistencia (resistencia) {
      if (resistencia === 1) {
        return 'Boa'
      } else if (resistencia === 2) {
        return 'Regular'
      } else {
        return 'Fraca'
      }
    },
    getPesoStatus (peso) {
      if (peso === 1) {
        return 'Leve'
      } else if (peso === 2) {
        return 'Normal'
      } else {
        return 'Pesado'
      }
    },
    getPeso (peso) {
      if (peso < 4.6) {
        return 'Leve'
      } else if (peso >= 4.6 && peso < 5.6) {
        return 'Normal'
      } else {
        return 'Pesado'
      }
    },

    validate () {
      if (this.$refs.form.validate()) {
        this.showForm = false
        const index = this.editedIndex

        if (this.editedIndex > -1) {
          ApiService.put(`/qualidade-tapete/${this.editedItem.id}`, this.editedItem).then((res) => {
            Object.assign(this.data[index], res.data.data)
            this.showForm = true
            this.$refs.snackbar.show('Sucesso!', `Qualidade do Tapete ${this.editedItem.id} atualizada com sucesso`)
            this.getData()
            this.close()
          }).catch((error) => {
            this.showForm = true
            this.$refs.snackbar.show('Erro', 'Não foi possível atualizar a Qualidade do Tapete', 'danger')
            console.error('Erro: ', error)
          })
        } else {
          ApiService.post('/qualidade-tapete', this.editedItem).then((res) => {
            this.data.push(res.data.data)
            this.showForm = true
            this.$refs.snackbar.show('Sucesso!', `Qualidade do Tapete ${res.data.data.id} adicionada com sucesso`)
            this.getData()
            this.close()
          }).catch((error) => {
            this.showForm = true
            this.$refs.snackbar.show('Erro', 'Não foi possível adicionar a Qualidade do Tapete', 'danger')
            console.error('Erro: ', error)
          })
        }
      }
    },
    async editItem (item) {
      this.dialog = true
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.peso_aproximado = this.$stringFormat.formatNumber(this.editedItem.peso_aproximado)

      if (item.talhao_id) {
        this.talhoes = [{
          talhao_id: item.talhao_id,
          talhao: item.talhao_nome,
        }]

        this.editedItem.talhao_id = parseInt(item.talhao_id)
      }

      this.setProducoesOptions(this.editedItem.empresa_id)
    },
    deleteItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.loadingDelete = true

      ApiService.delete(`/qualidade-tapete/${this.editedItem.id}`).then(() => {
        this.$refs.snackbar.show('Sucesso!', `Qualidade do Tapete ${this.editedItem.id} removida com sucesso`)
        this.loadingDelete = false
        this.closeDelete()
        this.getData()
      }).catch((error) => {
        this.loadingDelete = false
        this.$refs.snackbar.show('Erro', 'Não foi possível remover a Qualidade do Tapete', 'danger')
        console.error('Erro: ', error)
        this.closeDelete()
      })
    },
    close () {
      this.dialog = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },
    closeDelete () {
      this.dialogDelete = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.data[this.editedIndex], this.editedItem)
      } else {
        this.data.push(this.editedItem)
      }

      this.close()
    },

    periodoData (valor) {
      if (this.dataInicioFilter === '' && this.dataFimFilter === '') {
        return valor
      } else if (valor >= this.dataInicioFilter && this.dataFimFilter === '') {
        return valor
      } else if (valor <= this.dataFimFilter && this.dataInicioFilter === '') {
        return valor
      } else if (valor >= this.dataInicioFilter && valor <= this.dataFimFilter) {
        return valor
      }
    },

    async exportFile (tipo) {
      if (this.data.length > 0) {
        const url = tipo === 'Excel' ? '/relatorios/exportar' : '/relatorios/exportar-pdf'
        const filtros = {
          empresa_id: this.empresaFilterValue > 0 ? this.empresaFilterValue : '0',
          producao_id: this.producaoFilterValue.toString() ? this.producaoFilterValue.toString() : '0',
          variedade_cultura_id: this.culturaFilterValue !== '' ? this.culturaFilterValue : '0',
          resistencia: this.resistenciaFilterValue !== '' ? this.resistenciaFilterValue : '0',
          peso: this.pesoStatusFilterValue !== '' ? this.pesoStatusFilterValue : '0',
          status: this.statusFilterValue.toString(),
          data_inicio: this.dataInicioFilter !== '' ? this.dataInicioFilter : '0',
          data_fim: this.dataFimFilter !== '' ? this.dataFimFilter : '0',
          search: this.search !== '' ? this.search : '0',
        }

        const params = {
          relatorio: tipo === 'Excel' ? 'QualidadeTapetes' : 'qualidade-tapetes/qualidade-tapetes',
          parametros: JSON.stringify(filtros),
        }

        this.$refs.snackbar.show('Gerando o arquivo', 'Aguarde alguns segundos para baixar o arquivo', 'success', -1, true)

        ApiService.post(url, params).then((res) => {
          if (res.data.data.arquivo !== '') {
            this.$refs.snackbar.show('Arquivo gerado com sucesso', '', 'success')
            window.open(res.data.data.arquivo, '_blank')
          } else {
            this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          }
        }).catch((error) => {
          this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          console.error('Erro: ', error)
        })
      } else {
        this.$refs.snackbar.show('Erro', 'Não foram encontrados dados para exportação!', 'danger')
      }
    },

    async buscarTalhoes () {
      const params = `?page=1&qtde=50&sortBy=data_inicial&sortDesc=true&empresa_id=${this.editedItem.empresa_id}&producao_id=${this.editedItem.producao_id}&situacao=1`
      const talhoes = await ApiService.get('/liberacao-colheita', params)
      this.talhoes = talhoes.data.data
    },

    setVariedadeCultura (talhaoId) {
      for (const talhao of this.talhoes) {
        if (talhao.talhao_id === talhaoId) {
          this.editedItem.variedade_cultura_id = talhao.variedade_cultura_id
        }
      }
    },
  },
}
</script>
